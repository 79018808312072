import './App.css';
import { TwitterOutlined, GithubOutlined, LinkedinOutlined, ArrowUpOutlined, MoonOutlined, SunOutlined, MenuOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography, Tag, ConfigProvider, FloatButton, theme, Layout, Popover, Menu, Image, Carousel } from 'antd';
import experiences from '../src/data/experiences.json';
import projects from '../src/data/projects.json';
import { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import gb from './data/gb.jpg';

import books from '../src/data/books.json';
import { storage } from './firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const { Title, Paragraph } = Typography;

const { Content } = Layout;
const interests = ["Machine Learning", "Data", "Art", "UI/UX", "Gaming", "Economics", "Generative Systems", "Manufacturing", "Startups"];

interface BookContent {
  [key: string]: string;
}

interface BookPages {
  [key: string]: string[];
}

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [visible, setVisible] = useState(false);
  const [bookContent, setBookContent] = useState<BookContent>({});
  const [pages, setPages] = useState<BookPages>({});


  useEffect(() => {
    books.forEach(book => {
      async function fetchBooksContent() {
        const contentRef = ref(storage, book.content);
        const contentUrl = await getDownloadURL(contentRef);
        fetch(contentUrl)
          .then(response => response.text())  // Assuming the content is text/Markdown
          .then(markdown => {
            setBookContent(prev => ({ ...prev, [book.id]: markdown }));
          });
        const pagesUrls = await Promise.all(book.pages.map(page => getDownloadURL(ref(storage, page))));

        setPages(prev => ({ ...prev, [book.id]: pagesUrls }));
      }
      fetchBooksContent();


    });
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = isDarkMode ? '#1e1e1e' : '#ffffff';
  }, [isDarkMode]); // This effect runs every time `isDarkMode` changes.

  const handleClick = () => {
    setIsDarkMode(prev => !prev);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const content = (
    <Menu
      onClick={() => setVisible(false)} // Close menu when an item is clicked
      style={{ border: 'none', backgroundColor: isDarkMode ? '#1e1e1e' : '#ffffff' }}
    >
      <Menu.Item key="about">
        <a href="#about">About</a>
      </Menu.Item>
      <Menu.Item key="experiences">
        <a href="#experiences">Experiences</a>
      </Menu.Item>
      <Menu.Item key="projects">
        <a href="#projects">Projects</a>
      </Menu.Item>
      <Menu.Item key="notes">
        <a href="#notes">Notes</a>
      </Menu.Item>
      <Menu.Item key="ideas">
        <a href="#frameworks">Ideas</a>
      </Menu.Item>

    </Menu>
  );


  return (
    <ConfigProvider theme={{
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      token: {
        colorBgContainer: isDarkMode ? '#1e1e1e' : '#f5f5f5',
        colorPrimary: "#f5222d",
        colorInfo: "#f5222d"
      }

    }}>
      <Popover
        content={content}
        trigger="click"
        open={visible}
        onOpenChange={handleVisibleChange}
        placement="bottomLeft"
        overlayStyle={{ padding: 0 }}
      >
        <Button className="nav-button" type='primary' shape='circle' icon={<MenuOutlined />} style={{ position: 'fixed', zIndex: 1000 }} />
      </Popover>

      <Content>
        <div className="App">
          <Row gutter={24}>
            <Col xs={24} lg={12} className="info-section">
            <Row style={{ alignContent: 'center'}}>
                <Image
                  src={gb}
                  alt="Profile Picture"
                  width={50}
                  height={50}
                  style={{ borderRadius: '50%'}}
                />
                
                  <Title style={{marginLeft: '20px', marginTop: 0, marginBottom: 0}}>Grant Burke</Title>
                  
                
              </Row>
              <Title level={3} style={{ marginBottom: 0}}>Data Engineer &</Title>
              <Title level={3} style={{ marginBottom: 10, marginTop: 0}}>Project Manager</Title>
              <Paragraph>I like building data systems for business and helping teams bring their ideas to life.</Paragraph>
              <Title level={5}>INTERESTS</Title>
              <Row style={{ marginBottom: '1em' }} className="interest-ticker">
                <div >
                  {interests.map((interest, index) => (
                    <span key={index} style={{ marginRight: '50px' }}>{interest}</span>
                  ))}
                </div>
              </Row>
              <Row style={{ marginBottom: '1em' }}>
                <Button icon={<TwitterOutlined />} href="https://twitter.com/granttburke" target="_blank" shape="circle" style={{ marginRight: '20px' }} />
                <Button icon={<GithubOutlined />} href="https://github.com/gtb123" target="_blank" shape="circle" style={{ marginRight: '20px' }} />
                <Button icon={<LinkedinOutlined />} href="https://linkedin.com/in/granttburke" target="_blank" shape="circle" style={{ marginRight: '20px' }} />
                <Button icon={<MailOutlined />} href="mailto:grantburke123@gmail.com" target="_blank" shape="circle" />
              </Row>
            </Col>
            <Col xs={24} lg={12} className="scrollable-section">
              <section id="about">
                <Paragraph>I am a data engineer with experience in building data systems for business. I have a background in business and data analysis. I am passionate about building data systems that help businesses make better decisions. I have recently started working as a project manager where I help teams bring their ideas to life.</Paragraph>
                <Paragraph>Outside of work, I like to read, play games, and hang out with my friends and family. The only social network I maintain is Twitter (<a href="https://twitter.com/granttburke" target="_blank" rel="noopener noreferrer">@granttburke</a>). On occasion I will read or watch something interesting and I will write up a <a href="#notes">Note</a> about the information.  I enjoy being a soundboard for people's ideas, the energy of people working on new things is really infectious. I'll add more writing here in the future under ideas. You can see small web apps that I have built under <a href="#projects">Projects</a>.</Paragraph>
              </section>
              <section id="experiences">
                <Title className="sticky-header" level={2}>Experiences</Title>
                {experiences.map((experience) => (
                  <div key={experience.companyName}>
                    <Title level={3} style={{ marginBottom: '.25rem' }}>{experience.companyName} <a href={experience.companyURL} target="_blank" rel="noopener noreferrer"><ArrowUpOutlined rotate={45} /></a></Title>
                    <Paragraph style={{ color: '#a9a9a9', fontWeight: 'bold', marginBottom: '.25rem' }}>{experience.yearStart} - {experience.yearEnd}</Paragraph>
                    <Paragraph style={{ fontWeight: 'bold', color: "#f5222d" }}>{experience.position}</Paragraph>
                    <Paragraph>{experience.description}</Paragraph>
                    {experience.technologies && (
                      <div>
                        {experience.technologies.map((technology) => (
                          <Tag style={{ marginBottom: '4px' }} color="red" key={technology}>{technology}</Tag>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </section>
              <section id="projects">
                <Title className="sticky-header" level={2}>Projects</Title>
                {projects.map((project) => (
                  <>
                    <div key={project.projectName}>
                      <Title level={3} style={{ marginBottom: '.25rem' }}>{project.projectName} <a href={project.projectURL} target="_blank" rel="noopener noreferrer"><ArrowUpOutlined rotate={45} /></a></Title>
                      <Paragraph>{project.projectDescription}</Paragraph>
                    </div>
                    <>
                      {project.technologies && (
                        <div>
                          {project.technologies.map((technology) => (
                            <Tag style={{ marginBottom: '4px' }} color="red" key={technology}>{technology}</Tag>
                          ))}
                        </div>
                      )}
                    </>
                  </>

                ))}

              </section>
              <section id="notes" style={{ marginTop: '1rem' }}>
                <Title className="sticky-header" level={2}>Notes</Title>
                <Paragraph>Notes typically come from books I read, podcast, classes, and other material. I will typically write up a note if I find the material interesting. I will include my handwritten note above the text version. Topics range from data, UI/UX and business.</Paragraph>
                <div>
                  {books.map((book) => (
                    <>
                    <div key={book.id} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                      <div style={{ flex: 1 }}>
                        <Title level={3}>{book.title}</Title>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                          {book.tags.map((tag) => (
                            <Tag color="blue" key={tag}>{tag}</Tag>
                          ))}
                        </div>
                      </div>
                      {pages[book.id] && pages[book.id].length > 0 && (<Carousel autoplay style={{ width: '150px', height: '150px', borderRadius: '10px', overflow: 'hidden', marginLeft: '20px' }}>
                        {pages[book.id].map((page, index) => (
                          <div key={index}>
                            <Image src={page} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                          </div>
                        ))}
                      </Carousel>)}
                      
                    </div>
                  
                    <Markdown remarkPlugins={[remarkGfm]}>{bookContent[book.id]}</Markdown>
                    </>
                  ))}
                </div>
              </section>

            </Col>
          </Row>
        </div>

        <FloatButton onClick={handleClick} icon={isDarkMode ? <SunOutlined /> : <MoonOutlined />} style={{ zIndex: 1000 }} />
      </Content>
    </ConfigProvider>
  );
}

export default App;
